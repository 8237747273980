<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!-- <v-card> -->
        <!-- <v-card-title class="font-weight-bold"><div>Paket Custom</div></v-card-title> -->
        <v-row class="mt-0">
          <div class="col-md-6 col-lg-6 col-sm-12">
            <v-card class="mb-0 elevation-1 pa-5">
              <v-select
                v-model="selectType"
                :items="ContentType"
                item-text="label"
                item-value="value"
                label="Tipe Konten"
                :rules="[v => !!v || 'Tipe belum dipilih']"
                outlined
                required
                v-on:change="checkForm"
              ></v-select>

              <v-select
                v-model="selectFormat"
                :items="filteredFormat"
                item-text="label"
                item-value="value"
                label="Format Konten"
                :rules="[v => !!v || 'Format belum dipilih']"
                outlined
                required
                v-on:change="checkForm"
              ></v-select>

              <v-select
                v-model="selectLevel"
                :items="Level"
                item-text="label"
                item-value="value"
                label="Level Influencer"
                :rules="[v => !!v || 'Level belum dipilih']"
                outlined
                required
                v-on:change="checkForm"
              ></v-select>

              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 pb-0 mb-0">
                  <div class="pt-1 subtitle-1">Jumlah Influncer</div>
                </div>
                <div v-if="!isMobile()" class="col-md-6 col-lg-6 pb-0 mb-0">
                  <div class="row">
                    <div class="col-md-4 col-sm-4">
                      <v-btn block class="pa-5" :disabled="disableMinus" small @click="decrement" color="primary" height="30">
                          <i class="fa fa-minus" aria-hidden="true"></i>
                      </v-btn>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <v-text-field
                        outlined
                        v-model="bpm"
                        class="centered-input font-weight-bold pb-0 mb-0"
                        :rules="influencerRules"
                        dense
                        required
                      ></v-text-field>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <v-btn block class="pa-5" :disabled="disablePlus" small color="primary" @click="increment">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-col v-else
                  class="d-flex"
                  cols="12"
                  sm="6"
                  lg="6"
                >
                  <v-btn class="pa-5" :disabled="disableMinus" small @click="decrement" color="primary" height="30">
                      <i class="fa fa-minus" aria-hidden="true"></i>
                  </v-btn>
                  <v-text-field
                    outlined
                    v-model="bpm"
                    max-w
                    class="ml-3 mr-3 centered-input font-weight-bold"
                    :rules="influencerRules"
                    dense
                    required
                  ></v-text-field>
                  <v-btn class="pa-5" :disabled="disablePlus" small color="primary" @click="increment">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </v-btn>
                </v-col>
              </div>
            </v-card>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12">
            <v-card class="elevation-1 mb-5" v-if="selectType != 'link-bio'">
              <div class="col-md-12">
                <p class="pl-1 pr-1 mb-0 pb-0 font-weight-bold">Estimasi Impression 
                  <span v-if="customData !== undefined && customData !== ''" class="float-right black--text font-weight-medium">
                    {{ customData.impression*bpm | numberFormat}}
                  </span>
                  <span v-else class="float-right black--text font-weight-medium">0</span>
                  <span v-if="isLoading"><br>
                    <v-progress-linear
                      color="secondary"
                      class="float-right black--text font-weight-medium"
                      indeterminate
                      rounded
                      height="6"
                      style="width:5%"
                    ></v-progress-linear>
                  </span>
                </p>
                <div class="caption pl-1">Angka diatas merupakan perkiraan, dapat lebih tinggi maupun lebih rendah disesuaikan dengan konten influencer</div>
              </div>
            </v-card>
            <v-card class="elevation-1">
              <v-list two-line>
                <template>
                  <v-subheader class="font-weight-bold body-1"
                  >
                    Harga Detail
                  </v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title >Harga Per Influencer</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text><h2>Rp <span v-if="customData !== undefined && customData !== ''">{{ customData.price | numberFormat}}</span><span v-if="isLoading"><v-progress-linear
                        color="secondary"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear></span></h2></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">Total Harga</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text><h2>Rp <span v-if="customData !== undefined && customData !== ''">{{ customData.price*bpm | numberFormat}}</span><span v-if="isLoading"><v-progress-linear
                        color="secondary"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear></span>
                      </h2></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div class="row ml-1 mr-1">

            <div class="col-md-12">
              <v-btn
                  color="primary"
                  class="text-capitalize"
                  block
                  @click="validate()"
                  :disabled="isLoadingSubmit"
                  >
                  <span v-if="isLoadingSubmit">
                  <v-progress-circular
                    color="grey"
                    size="25"
                    indeterminate
                    class="mr-2"
                  ></v-progress-circular>
                  Loading . . .
                  </span>
                  <span v-else>
                  <i class="fas fa-user-check mr-2"></i>
                  <!-- <b-icon icon="cart3" class="white--text text-24 mr-2"></b-icon>  -->
                  Lanjut Pilih Influencer</span>
              </v-btn>
            </div>
            </div>
            </v-card>
          </div>
        </v-row>
        <br>
      <!-- </v-card> -->
    </v-form>
    <br>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ListInfluencers from './Table.vue'
import axios from 'axios'

export default defineComponent({
  setup() {
    return{
      bpm: 1,
      customData:'',
      maxInfluencers:250,
      disableMinus:true,
      disablePlus:false,
      valid: true,
      selectType: null,
      selectFormat: null,
      selectLevel: null,
      selected:0,
      isLoading:false,
      isLoadingSubmit:false,
      itemData:'',
      ContentType: [
        {label:'Review Produk', value:'review-product'},
        {label:'Paid Promote', value:'paid-promote'},
        {label:'Attendance', value:'attendance'},
        {label:'Link on Bio', value:'link-bio'},
      ],
      ContentFormat: [
        {label:'Story', value:'story'},
        {label:'Feed Photo', value:'feed-photo'},
        {label:'Feed Video', value:'feed-video'},
        {label:'Link (Website)', value:'link-bio'}
      ],
      Level: [
        {label:'Nano (1K-9K Follower)', value:'A'},
        {label:'Micro (10K-100K Follower)', value:'B'}
      ],
      influencerRules: [ 
          v => !!v || "Tidak boleh kosong", 
          v => ( v && v >= 1 ) || "Minimum 1",
          v => ( v && v <= 250 ) || "Maximum 250",
      ],
      items: [
        { header: 'Influencer Detail' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Harga Per Influencer', subtitle: `<span class="font-weight-bold">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?` },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Total Biaya', subtitle: `<span class="font-weight-bold">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.` }
      ],
    }
  },
  components: {
    ListInfluencers
  },
  computed: {
    filteredFormat(){
      if(this.selectType === 'link-bio'){
        return this.ContentFormat.filter(item => item.value === 'link-bio')
      } else {
        return this.ContentFormat.filter(item => item.value != 'link-bio')
      }
    }
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    checkForm(){
      if(this.selectType != null && this.selectFormat != null && this.selectLevel != null){
        this.isLoading = true;
        let token = localStorage.getItem('token')
        axios.get(
          process.env.VUE_APP_API_ENDPOINT + 'influencers/getRate/'+this.selectLevel+'/'+this.selectType+'/'+this.selectFormat+'/instagram', {
            headers: {
            'Authorization': 'Bearer '+ token
          }}).then(response => {
            console.log(response)
            if(response.status === 200){
              let data = response.data.data;
              setTimeout(() => { 
                this.customData = data
                this.isLoading = false
              }, 500);
            }
          }).catch(error => {
            console.log(error)
        });
      }
    },
    submit(){
      this.isLoadingSubmit = true;
      let token = localStorage.getItem('token')
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'packages/addCustomPackage', {
          type:this.selectType,
          level: this.selectLevel,
          qty: this.bpm,
          price:this.bpm*this.customData.price,
          impression:this.bpm*this.customData.impression,
          duration:this.customData.duration,
          format:this.selectFormat
        }, {
          headers: {
          'Authorization': 'Bearer '+ token
        }
      }).then(response => {
        if(response.status === 201){
          this.itemData = response.data.data
          setTimeout(() => { 
            this.isLoadingSubmit = false
            this.goToChooseInfluencer()
          }, 200);
        }
      }).catch(error => {
        console.log(error)
        setTimeout(() => { 
          this.isLoadingSubmit = false
        }, 200);
      });
    },
    decrement () {
      if(this.bpm <= 1){
        this.disableMinus = true
      } else {
        this.disableMinus = false
        this.bpm--
        if(this.bpm <= 1){
          this.disableMinus = true
        }
      }
      if(this.bpm >= this.maxInfluencers){
        this.disablePlus = true
      } else {
        this.disablePlus = false
      }
    },
    increment () {
      if(this.bpm >= this.maxInfluencers){
        this.disablePlus = true
      } else {
        this.disablePlus = false
        this.bpm++
         if(this.bpm >= this.maxInfluencers){
          this.disablePlus = true
        }
      }
      if(this.bpm <= 1){
        this.disableMinus = true
      } else {
        this.disableMinus = false
      }
    },
    validate () {
      let checkValid = this.$refs.form.validate()
      if(checkValid){
        // this.goToChooseInfluencer('valid');
        this.submit()
      }
    },
    goToChooseInfluencer(){
      this.$router.push({name:'campaign-choose-influencer', query: {package: this.encryptData(this.$route.query.item), item: this.encryptData(this.itemData.id)}}); 
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  },
})
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center;
    }
</style>