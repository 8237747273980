<template>
  <div>
    <v-card>
        <!-- <v-subheader class="font-weight-bold">Paket</v-subheader> -->
        <v-card-title class="font-weight-bold"><div>Kategori Influencer</div><div class="font-weight-100 subtitle-2" style="margin-left:auto">Lihat Semua <v-icon size="20" class="mr-2">{{ icons.mdiChevronRight }}</v-icon></div></v-card-title>
        <v-slide-group
          class="pb-5"
            active-class="success"
            >
            <v-slide-item
                v-for="n in 10"
                :key="n"
            >
                <v-card 
                    color="primary"
                    height="110"
                    width="200"
                    class="ma-2"
                >
                    <v-card-text class="d-flex flex-column justify-center align-center">
                        <!-- <v-avatar
                        color="secondary"
                        icon
                        size="30"
                        > -->
                        <v-icon
                            size="2rem"
                            color="white"
                        >
                            {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                        <!-- </v-avatar> -->
                        <p class="text-xl mt-4 font-weight-medium white--text">
                        Fashion
                        </p>
                    </v-card-text>
                    </v-card>
            </v-slide-item>
            </v-slide-group>
    </v-card>
    <br>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronRight,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'

export default {
  setup() {
    const isCardDetailsVisible = false
    const rating = ref(5)

    return {
      isCardDetailsVisible,
      rating,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiChevronRight,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>