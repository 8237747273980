<template>
  <v-row>
    <v-col cols="12">
        <!-- <steppers-campaign></steppers-campaign> -->
        <!-- <category-item></category-item> -->
        <package-item></package-item>
        <!-- <unit-influencer></unit-influencer> -->
        <!-- <daftar-influencer></daftar-influencer> -->
    </v-col>
  </v-row>
</template>

<script>
import PackageItem from './components/Packages.vue'
import CategoryItem from './components/Categories.vue'

export default {
  components: {
    PackageItem,
    CategoryItem,
  },
  setup() {
    return {}
  },
}
</script>
