<template>
  <div>
    <!-- <v-card>
      <v-card-title class="font-weight-bold"><div>Paket Paid Promote / Repost</div><div class="font-weight-100 subtitle-2" style="margin-left:auto">Lihat Semua <v-icon size="20" class="mr-2">{{ icons.mdiChevronRight }}</v-icon></div></v-card-title>
      <v-slide-group
        class="pb-4"
        active-class="success"
        >
        <v-slide-item
            v-for="n in 10"
            :key="n"
            v-slot="{ active, toggle }"
        > -->
    <p class="headline black--text font-weight-bold">{{ packageName }}</p>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 4" :key="n"
            class="mx-auto pa-1"
            width="300"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 4" :key="n"
            class="mx-auto pa-1"
            width="300"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <div v-if="packageName != 'Custom Package'">
        <v-card color="transparent">
          <v-card-title class="font-weight-medium"><div>Nano Influencer (1k - 9k Followers)</div><div class="font-weight-100 subtitle-2" style="margin-left:auto"></div></v-card-title>
          <div class="row ml-3 mr-3">
            <div class="col-md-3 d-flex justify-center" v-for="list in nanoPackages" :key="list.id">
              <v-card
                class="mt-0"
                height="300"
                width="300"
                @click="goToChooseInfluencer(list.id)"
              >
                <!-- <v-img src="@/assets/images/pages/card-basic-brown-watch.jpg"></v-img> -->
                <v-card-title><div class="black--text font-weight-bold">{{ list.name }}</div></v-card-title>
                <v-card-text>
                    <p class="secondary--text font-weight-bold subtitle-1">
                    Rp {{ list.price | numberFormat }}
                    </p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>{{ list.content }}</p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>{{ list.qty | numberFormat }} Nano Influencer</p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>Est. Impression {{ list.impression | numberFormat }}</p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>Est. Follower {{ list.follower | numberFormat }}</p>
                    <p class="text-capitalize"><i class="fas fa-check-circle mr-2 primary--text"></i>Durasi {{ list.duration }}</p>
                </v-card-text>
                <v-card-actions class="primary pa-0" style="position: absolute; bottom: 0; width:100%">
                  <v-btn
                    color="primary"
                    block
                    dark
                    large
                    class="text-capitalize"
                  >
                    <i class="fa fa-eye mr-2" aria-hidden="true"></i> Lihat Detail
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </v-card>
            <!-- </v-slide-item>
          </v-slide-group>
        </v-card> -->
        <br><br>
        <v-card color="transparent">
          <v-card-title class="font-weight-medium"><div>Micro Influencer (10k - 100k Followers)</div><div class="font-weight-100 subtitle-2" style="margin-left:auto"></div></v-card-title>
          <div class="row ml-3 mr-3">
            <div class="col-md-3 d-flex justify-center" v-for="list in microPackages" :key="list.id">
              <v-card
                class="mt-0"
                height="300"
                width="300"
                @click="goToChooseInfluencer(list.id)"
              >
                <v-card-title><div class="black--text font-weight-bold">{{ list.name }}</div></v-card-title>
                <v-card-text>
                    <p class="secondary--text font-weight-bold subtitle-1">
                    Rp {{ list.price | numberFormat }}
                    </p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>{{ list.content }}</p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>{{ list.qty | numberFormat }} Nano Influencer</p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>Est. Impression {{ list.impression | numberFormat }}</p>
                    <p class="mb-1"><i class="fas fa-check-circle mr-2 primary--text"></i>Est. Follower {{ list.follower | numberFormat }}</p>
                    <p class="text-capitalize"><i class="fas fa-check-circle mr-2 primary--text"></i>Durasi {{ list.duration }}</p>
                </v-card-text>
                <v-card-actions class="primary pa-0" style="position: absolute; bottom: 0; width:100%">
                  <v-btn
                    color="primary"
                    block
                    dark
                    large
                    class="text-capitalize"
                  >
                    <i class="fa fa-eye mr-2" aria-hidden="true"></i> Lihat Detail
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </v-card>
      </div>
      <div v-else>
        <unit-influencer></unit-influencer>
      </div>
    </div>
    <br> 
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import UnitInfluencer from './Unit.vue'
import axios from 'axios'

export default {
  components:{
    UnitInfluencer
  },
  setup() {
    const isCardDetailsVisible = false
    const rating = ref(5)

    return {
      isCardDetailsVisible,
      rating,
      packageName:'',
      listPackages:[],
      isLoading:true,
      microPackages:[],
      nanoPackages:[],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  mounted(){
    this.loadPackages()
    if(this.$route.query.item === 'basic'){
      this.packageName = 'Basic Package'
    } else if (this.$route.query.item === 'premium'){
      this.packageName = 'Premium  Package'
    } else if (this.$route.query.item === 'custom') {
      this.packageName = 'Custom Package'
    } else {
      this.$router.push('error-404');
    }
  },
  methods: {
    loadPackages(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'packages/getPackages', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            this.listPackages = response.data
            let data = response.data
            for(let a=0; a < data.length; a++){
              if(this.$route.query.item === 'basic'){
                if(data[a].description.includes("Basic")){
                  if(data[a].type === 'B'){
                    this.microPackages[a] = data[a];
                  } else if(data[a].type === 'A') {
                    this.nanoPackages[a] = data[a]
                  }
                }
              } else if (this.$route.query.item === 'premium'){
                if(data[a].description.includes("Premium")){
                  if(data[a].type === 'B'){
                    this.microPackages[a] = data[a];
                  } else if(data[a].type === 'A') {
                    this.nanoPackages[a] = data[a]
                  }
                }
              }
            }
            this.nanoPackages = this.nanoPackages.filter(value => Object.keys(value).length !== 0);
            this.microPackages = this.microPackages.filter(value => Object.keys(value).length !== 0);
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
          // setTimeout(() => { 
          //   this.isLoading = false; 
          //   this.alertType='error';
          //   this.alertMessage='Email / Password Salah';
          //   this.alertStatus=true;
          //   this.resetForm();
          // }, 2000);
      });
    },
    goToChooseInfluencer(item){
      // this.$router.push('/campaign/choose-influencer'); 
      this.$router.push({name:'campaign-choose-influencer', query: {package: this.encryptData(this.$route.query.item), item: this.encryptData(item)}}); 
    },
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>